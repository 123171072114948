require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/4a82504ac5a365b515498484f6caee02/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4a82504ac5a365b515498484f6caee02'] = async function fn_4a82504ac5a365b515498484f6caee02(tableName, whereClause, propertiesToInclude, propertiesToExclude, propertiesToSortBy, relations) {
var allCustomers, loading, currentBatch, offset;


  loading = true;
  allCustomers = [];
  offset = 0;
  while (loading) {
    currentBatch = (await Backendless.Data.of(tableName).find(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setProperties(propertiesToInclude).excludeProperties(propertiesToExclude).setRelated(relations).setSortBy(propertiesToSortBy).setPageSize(100).setOffset(offset)));
    allCustomers = (allCustomers.concat(currentBatch));
    offset = offset + 100;
    if (currentBatch.length < 100) {
      loading = false;
    }
  }

return allCustomers
}

})
define('./functions/ce6a7744410fa122d5ca861572a6cbf6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_ce6a7744410fa122d5ca861572a6cbf6'] = async function fn_ce6a7744410fa122d5ca861572a6cbf6(tableName, dataModel, whereClause, propertiesToInclude, propertiesToExclude, propertiesToSortBy, relations) {
var loadedData, offset;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function init_offset_var() {
  offset = (getObjectProperty(dataModel, 'offset'));
  if (offset == null) {
    offset = 0;
  }
}

/**
 * Describe this function...
 */
async function load_data() {
  loadedData = (await Backendless.Data.of(tableName).find(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setProperties(propertiesToInclude).excludeProperties(propertiesToExclude).setRelated(relations).setSortBy(propertiesToSortBy).setPageSize(100).setOffset(offset)));
}

/**
 * Describe this function...
 */
async function adjust_page_data_state() {
  if (loadedData.length < 100) {
    dataModel['loadingData'] = false;
  } else {
    dataModel['offset'] = (offset + loadedData.length);
  }
}


  await init_offset_var();
  await load_data();
  await adjust_page_data_state();

return loadedData
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('mainCarrierPage', undefined);

  },
  /* handler:onClick *//* content */
}));

define('./pages/carrierEquipmentSummary/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var equipmentTableName, userObject, tempClassesVariable, counter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setUserProperties() {
  ___arguments.context.pageData['userTextLabel'] = (String('User: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'name')));
  ___arguments.context.pageData['maxSearchesTextLabel'] = (String('Max monthly searches: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'maxSearches')));
  ___arguments.context.pageData['currentSearchesTextLabel'] = (String('Current searches: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'currentSearches')));
  ___arguments.context.pageData['licenseEndDateTextLabel'] = (String('License valid through: ') + String((new Date(((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'licenseEndDate')).valueOf()))).toLocaleDateString()));
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}

/**
 * Describe this function...
 */
async function updateSearchCount() {
  userObject['currentSearches'] = ((getObjectProperty(userObject, 'currentSearches')) + 1);
  await Backendless.Data.of('Users').save(userObject, true);
  ___arguments.context.pageData['currentSearchesTextLabel'] = (String('Current searches: ') + String(getObjectProperty(userObject, 'currentSearches')));
}

function getValueLength(value) {
 if (typeof value === 'number') {
   value = `${ value }`
 }

 return value.length
}

/**
 * Describe this function...
 */
async function setCarrierProperties() {
  ___arguments.context.pageData['dotNumberInputValue'] = (getObjectProperty(___arguments.context.pageData, 'dot_number'));
  ___arguments.context.pageData['dotText'] = (getObjectProperty(___arguments.context.pageData, 'dot_number'));
  ___arguments.context.pageData['nameText'] = (textToTitleCase(getObjectProperty(___arguments.context.pageData, 'name')));
  ___arguments.context.pageData['emissionsFactor82Text'] = (getObjectProperty(___arguments.context.pageData, 'two_year_co2_ef_8'));
  ___arguments.context.pageData['efPercentile82Text'] = (getObjectProperty(___arguments.context.pageData, 'ef_percentile_8'));
  ___arguments.context.pageData['emissionsFactorText'] = (getObjectProperty(___arguments.context.pageData, 'two_year_co2_ef'));
  ___arguments.context.pageData['efPercentileText'] = (getObjectProperty(___arguments.context.pageData, 'ef_percentile'));
  ___arguments.context.pageData['currentEFText'] = (getObjectProperty(___arguments.context.pageData, 'current_best_estimate_8'));
  ___arguments.context.pageData['swEquivalentText'] = (getObjectProperty(___arguments.context.pageData, 'smartway_class'));
  ___arguments.context.pageData['emissionsFactor32Text'] = (getObjectProperty(___arguments.context.pageData, 'two_year_co2_ef_3'));
  ___arguments.context.pageData['emissionsFactor452Text'] = (getObjectProperty(___arguments.context.pageData, 'two_year_co2_ef_45'));
  ___arguments.context.pageData['emissionsFactor672Text'] = (getObjectProperty(___arguments.context.pageData, 'two_year_co2_ef_67'));
  ___arguments.context.pageData['emissionsFactor81Text'] = (getObjectProperty(___arguments.context.pageData, 'one_year_co2_ef_8'));
  ___arguments.context.pageData['emissionsFactor31Text'] = (getObjectProperty(___arguments.context.pageData, 'one_year_co2_ef_3'));
  ___arguments.context.pageData['emissionsFactor451Text'] = (getObjectProperty(___arguments.context.pageData, 'one_year_co2_ef_45'));
  ___arguments.context.pageData['emissionsFactor671Text'] = (getObjectProperty(___arguments.context.pageData, 'one_year_co2_ef_67'));
  counter = 1;
  if (getValueLength((JSON.stringify((getObjectProperty(___arguments.context.pageData, 'classes'))))) > 1) {
    console.log('passed if');
    tempClassesVariable = '';
    var repeat_end = getValueLength((JSON.stringify((getObjectProperty(___arguments.context.pageData, 'classes')))));
    for (var count = 0; count < repeat_end; count++) {
      if (counter == getValueLength((JSON.stringify((getObjectProperty(___arguments.context.pageData, 'classes')))))) {
        tempClassesVariable = String(tempClassesVariable) + String((JSON.stringify((getObjectProperty(___arguments.context.pageData, 'classes')))).slice((counter - 1), counter));
      } else {
        tempClassesVariable = String(tempClassesVariable) + String(String((JSON.stringify((getObjectProperty(___arguments.context.pageData, 'classes')))).slice((counter - 1), counter)) + String(','));
        counter = counter + 1;
      }
    }
  } else {
    tempClassesVariable = (getObjectProperty(___arguments.context.pageData, 'classes'));
  }
  ___arguments.context.pageData['classesText'] = tempClassesVariable;
  ___arguments.context.pageData['cityText'] = (textToTitleCase(getObjectProperty(___arguments.context.pageData, 'phy_city')));
  ___arguments.context.pageData['stateText'] = (getObjectProperty(___arguments.context.pageData, 'phy_st'));
  ___arguments.context.pageData['countryText'] = (getObjectProperty(___arguments.context.pageData, 'phy_natn'));
  ___arguments.context.pageData['zipText'] = (getObjectProperty(___arguments.context.pageData, 'phy_zip'));
  ___arguments.context.pageData['foundedText'] = ([(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'add_date')))).slice(4, 6),'/',(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'add_date')))).slice(6, 8),'/',(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'add_date')))).slice(0, 4)].join(''));
  ___arguments.context.pageData['countryText'] = (getObjectProperty(___arguments.context.pageData, 'phy_natn'));
  ___arguments.context.pageData['averageMYText'] = (getObjectProperty(___arguments.context.pageData, 'avg_vehicle_age'));
  if ((getObjectProperty(___arguments.context.pageData, 'general_freight')) == 1) {
    ___arguments.context.pageData['generalFreightText'] = 'Yes';
  } else {
    ___arguments.context.pageData['generalFreightText'] = 'No';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'for_hire')) == 1) {
    ___arguments.context.pageData['forHireText'] = 'Yes';
  } else {
    ___arguments.context.pageData['forHireText'] = 'No';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'intermodal')) == 1) {
    ___arguments.context.pageData['intermodalText'] = 'Yes';
  } else {
    ___arguments.context.pageData['intermodalText'] = 'No';
  }
  ___arguments.context.pageData['reportedTrucksText'] = (getObjectProperty(___arguments.context.pageData, 'tot_pwr'));
  ___arguments.context.pageData['nbrDriversText'] = (getObjectProperty(___arguments.context.pageData, 'tot_drs'));
  ___arguments.context.pageData['calculatedTrucksText'] = (getObjectProperty(___arguments.context.pageData, 'calculated_tot_pwr'));
}


  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
  } else {
    userObject = (await Backendless.UserService.getCurrentUser(true));
    ___arguments.context.pageData['twoYearRadio'] = 'twoYearChecked';
    await setUserProperties();
    if (typeof (getObjectProperty(___arguments.context.pageData, 'name')) === 'undefined') {
      ___arguments.context.pageData['pageTitle'] = 'Carrier';
      ___arguments.context.pageData['dotNumberInputValue'] = '';
    } else {
      ___arguments.context.pageData['pageTitle'] = (String('Equipment summary for ') + String(textToTitleCase(getObjectProperty(___arguments.context.pageData, 'name'))));
      await updateSearchCount();
      await setCarrierProperties();
      if ((getObjectProperty(___arguments.context.pageData, 'dot_number')) < 2500000) {
        equipmentTableName = 'trucks_pull1';
      } else {
        equipmentTableName = 'trucks_pull2';
      }
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c11ea5c6a35b2be3860e9222791ce266', (await BackendlessUI.Functions.Custom['fn_4a82504ac5a365b515498484f6caee02'](equipmentTableName, (String('dot_number = ') + String(getObjectProperty(___arguments.context.pageData, 'dot_number'))), null, null, 'nbr_obs desc', null)));
    }
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/carrierEquipmentSummary/components/f3d42bc37aba00457c6d25fefb770cf9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var classes, returnedCarrier, equipmentTableName, carrierSearch, userObject, tempClassesVariable, counter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}

/**
 * Describe this function...
 */
async function updateSearchCount() {
  userObject['currentSearches'] = ((getObjectProperty(userObject, 'currentSearches')) + 1);
  await Backendless.Data.of('Users').save(userObject, true);
  ___arguments.context.pageData['currentSearchesTextLabel'] = (String('Current searches: ') + String(getObjectProperty(userObject, 'currentSearches')));
}

function getValueLength(value) {
 if (typeof value === 'number') {
   value = `${ value }`
 }

 return value.length
}

/**
 * Describe this function...
 */
async function setCarrierProperties() {
  ___arguments.context.pageData['dotNumberInputValue'] = (getObjectProperty(returnedCarrier, 'dot_number'));
  ___arguments.context.pageData['dotText'] = (getObjectProperty(returnedCarrier, 'dot_number'));
  ___arguments.context.pageData['nameText'] = (textToTitleCase(getObjectProperty(returnedCarrier, 'name')));
  ___arguments.context.pageData['emissionsFactor82Text'] = (getObjectProperty(returnedCarrier, 'two_year_co2_ef_8'));
  ___arguments.context.pageData['efPercentile82Text'] = (getObjectProperty(returnedCarrier, 'ef_percentile_8'));
  ___arguments.context.pageData['emissionsFactorText'] = (getObjectProperty(returnedCarrier, 'two_year_co2_ef'));
  ___arguments.context.pageData['efPercentileText'] = (getObjectProperty(returnedCarrier, 'ef_percentile'));
  ___arguments.context.pageData['currentEFText'] = (getObjectProperty(returnedCarrier, 'current_best_estimate_8'));
  ___arguments.context.pageData['swEquivalentText'] = (getObjectProperty(returnedCarrier, 'smartway_class'));
  ___arguments.context.pageData['emissionsFactor32Text'] = (getObjectProperty(returnedCarrier, 'two_year_co2_ef_3'));
  ___arguments.context.pageData['emissionsFactor452Text'] = (getObjectProperty(returnedCarrier, 'two_year_co2_ef_45'));
  ___arguments.context.pageData['emissionsFactor672Text'] = (getObjectProperty(returnedCarrier, 'two_year_co2_ef_67'));
  ___arguments.context.pageData['emissionsFactor81Text'] = (getObjectProperty(returnedCarrier, 'one_year_co2_ef_8'));
  ___arguments.context.pageData['emissionsFactor31Text'] = (getObjectProperty(returnedCarrier, 'one_year_co2_ef_3'));
  ___arguments.context.pageData['emissionsFactor451Text'] = (getObjectProperty(returnedCarrier, 'one_year_co2_ef_45'));
  ___arguments.context.pageData['emissionsFactor671Text'] = (getObjectProperty(returnedCarrier, 'one_year_co2_ef_67'));
  counter = 1;
  if (getValueLength((getObjectProperty(returnedCarrier, 'classes'))) > 1) {
    tempClassesVariable = '';
    var repeat_end = getValueLength((getObjectProperty(returnedCarrier, 'classes')));
    for (var count = 0; count < repeat_end; count++) {
      if (counter == getValueLength((getObjectProperty(returnedCarrier, 'classes')))) {
        tempClassesVariable = String(tempClassesVariable) + String((getObjectProperty(returnedCarrier, 'classes')).slice((counter - 1), counter));
      } else {
        tempClassesVariable = String(tempClassesVariable) + String(String((getObjectProperty(returnedCarrier, 'classes')).slice((counter - 1), counter)) + String(','));
        counter = counter + 1;
      }
    }
  } else {
    tempClassesVariable = (getObjectProperty(returnedCarrier, 'classes'));
  }
  ___arguments.context.pageData['classesText'] = tempClassesVariable;
  ___arguments.context.pageData['cityText'] = (textToTitleCase(getObjectProperty(returnedCarrier, 'phy_city')));
  ___arguments.context.pageData['countryText'] = (getObjectProperty(returnedCarrier, 'phy_natn'));
  ___arguments.context.pageData['stateText'] = (getObjectProperty(returnedCarrier, 'phy_st'));
  ___arguments.context.pageData['zipText'] = (getObjectProperty(returnedCarrier, 'phy_zip'));
  ___arguments.context.pageData['foundedText'] = (getObjectProperty(returnedCarrier, 'add_date'));
  ___arguments.context.pageData['foundedText'] = ([(JSON.stringify((getObjectProperty(returnedCarrier, 'add_date')))).slice(5, 7),'/',(JSON.stringify((getObjectProperty(returnedCarrier, 'add_date')))).slice(7, 9),'/',(JSON.stringify((getObjectProperty(returnedCarrier, 'add_date')))).slice(1, 5)].join(''));
  ___arguments.context.pageData['averageMYText'] = (getObjectProperty(returnedCarrier, 'avg_vehicle_age'));
  if ((getObjectProperty(returnedCarrier, 'general_freight')) == 1) {
    ___arguments.context.pageData['generalFreightText'] = 'Yes';
  } else {
    ___arguments.context.pageData['generalFreightText'] = 'No';
  }
  if ((getObjectProperty(returnedCarrier, 'for_hire')) == 1) {
    ___arguments.context.pageData['forHireText'] = 'Yes';
  } else {
    ___arguments.context.pageData['forHireText'] = 'No';
  }
  if ((getObjectProperty(returnedCarrier, 'intermodal')) == 1) {
    ___arguments.context.pageData['intermodalText'] = 'Yes';
  } else {
    ___arguments.context.pageData['intermodalText'] = 'No';
  }
  ___arguments.context.pageData['reportedTrucksText'] = (getObjectProperty(returnedCarrier, 'tot_pwr'));
  ___arguments.context.pageData['nbrDriversText'] = (getObjectProperty(returnedCarrier, 'tot_drs'));
  ___arguments.context.pageData['calculatedTrucksText'] = (getObjectProperty(returnedCarrier, 'calculated_tot_pwr'));
}


  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
  } else {
    userObject = (await Backendless.UserService.getCurrentUser(true));
    try {
      if ((getObjectProperty(userObject, 'currentSearches')) > (getObjectProperty(userObject, 'maxSearches'))) {
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['label'] = 'Exceeded searches';
        await new Promise(r => setTimeout(r, 3000 || 0));
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
      } else if ((new Date((new Date()).getTime() + (86400 * 1000))) > ((getObjectProperty(userObject, 'licenseEndDate')).valueOf())) {
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['label'] = 'License expired';
        await new Promise(r => setTimeout(r, 3000 || 0));
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
      } else {
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['label'] = 'searching...';
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['disabled'] = true;
        if ((Number((getObjectProperty(___arguments.context.pageData, 'dotNumberInputValue')))) > 0) {
          carrierSearch = (await Backendless.Data.of('carrier_pull').find(Backendless.DataQueryBuilder.create().setWhereClause((String('dot_number = ') + String(getObjectProperty(___arguments.context.pageData, 'dotNumberInputValue')))).setPageSize(1)));
          returnedCarrier = carrierSearch[0];
          await updateSearchCount();
          ___arguments.context.pageData['pageTitle'] = (String('Equipment summary for ') + String(textToTitleCase(getObjectProperty(returnedCarrier, 'name'))));
          await setCarrierProperties();
          if ((getObjectProperty(___arguments.context.pageData, 'classesFilterValue')) == '') {
            classes = ' and class in(3,4,5,6,7,8) ';
          } else {
            classes = [' and class in(',(getObjectProperty(___arguments.context.pageData, 'classesFilterValue')),') '].join('');
          }
          if ((getObjectProperty(returnedCarrier, 'dot_number')) < 2500000 && (getObjectProperty(___arguments.context.pageData, 'twoYearRadio')) == 'twoYearChecked') {
            equipmentTableName = 'trucks_pull1';
          } else if ((getObjectProperty(returnedCarrier, 'dot_number')) >= 2500000 && (getObjectProperty(___arguments.context.pageData, 'twoYearRadio')) == 'twoYearChecked') {
            equipmentTableName = 'trucks_pull2';
          } else if ((getObjectProperty(returnedCarrier, 'dot_number')) < 2500000 && (getObjectProperty(___arguments.context.pageData, 'twoYearRadio')) == 'oneYearChecked') {
            equipmentTableName = 'trucks_pull1year_1';
          } else {
            equipmentTableName = 'trucks_pull1year_2';
          }
          (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c11ea5c6a35b2be3860e9222791ce266', (await BackendlessUI.Functions.Custom['fn_4a82504ac5a365b515498484f6caee02'](equipmentTableName, (['dot_number = ',(getObjectProperty(returnedCarrier, 'dot_number')),classes].join('')), null, null, 'nbr_obs desc', null)));
        }
      }

    } catch (error) {

    } finally {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['label'] = 'Search';
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['disabled'] = false;

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/carrierEquipmentSummary/components/2826bc2226c99584ff1c27a142fbfc41/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landingPage', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/carrierEquipmentSummary/components/9dcc3a9323e285016a1f999bd507ce52/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/carrierEquipmentSummary_archive/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var equipmentTableName, userObject, tempClassesVariable, counter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setUserProperties() {
  ___arguments.context.pageData['userTextLabel'] = (String('User: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'name')));
  ___arguments.context.pageData['maxSearchesTextLabel'] = (String('Max monthly searches: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'maxSearches')));
  ___arguments.context.pageData['currentSearchesTextLabel'] = (String('Current searches: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'currentSearches')));
  ___arguments.context.pageData['licenseEndDateTextLabel'] = (String('License valid through: ') + String((new Date(((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'licenseEndDate')).valueOf()))).toLocaleDateString()));
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}

/**
 * Describe this function...
 */
async function updateSearchCount() {
  userObject['currentSearches'] = ((getObjectProperty(userObject, 'currentSearches')) + 1);
  await Backendless.Data.of('Users').save(userObject, true);
  ___arguments.context.pageData['currentSearchesTextLabel'] = (String('Current searches: ') + String(getObjectProperty(userObject, 'currentSearches')));
}

/**
 * Describe this function...
 */
async function setCarrierProperties() {
  ___arguments.context.pageData['dotNumberInputValue'] = (getObjectProperty(___arguments.context.pageData, 'dot_number'));
  ___arguments.context.pageData['dotText'] = (getObjectProperty(___arguments.context.pageData, 'dot_number'));
  ___arguments.context.pageData['nameText'] = (textToTitleCase(getObjectProperty(___arguments.context.pageData, 'name')));
  ___arguments.context.pageData['emissionsFactor82Text'] = (getObjectProperty(___arguments.context.pageData, 'two_year_co2_ef_8'));
  ___arguments.context.pageData['efPercentile82Text'] = (getObjectProperty(___arguments.context.pageData, 'ef_percentile_8'));
  ___arguments.context.pageData['emissionsFactorText'] = (getObjectProperty(___arguments.context.pageData, 'two_year_co2_ef'));
  ___arguments.context.pageData['efPercentileText'] = (getObjectProperty(___arguments.context.pageData, 'ef_percentile'));
  ___arguments.context.pageData['currentEFText'] = (getObjectProperty(___arguments.context.pageData, 'current_best_estimate_8'));
  ___arguments.context.pageData['swEquivalentText'] = (getObjectProperty(___arguments.context.pageData, 'smartway_class'));
  ___arguments.context.pageData['emissionsFactor32Text'] = (getObjectProperty(___arguments.context.pageData, 'two_year_co2_ef_3'));
  ___arguments.context.pageData['emissionsFactor452Text'] = (getObjectProperty(___arguments.context.pageData, 'two_year_co2_ef_45'));
  ___arguments.context.pageData['emissionsFactor672Text'] = (getObjectProperty(___arguments.context.pageData, 'two_year_co2_ef_67'));
  ___arguments.context.pageData['emissionsFactor81Text'] = (getObjectProperty(___arguments.context.pageData, 'one_year_co2_ef_8'));
  ___arguments.context.pageData['emissionsFactor31Text'] = (getObjectProperty(___arguments.context.pageData, 'one_year_co2_ef_3'));
  ___arguments.context.pageData['emissionsFactor451Text'] = (getObjectProperty(___arguments.context.pageData, 'one_year_co2_ef_45'));
  ___arguments.context.pageData['emissionsFactor671Text'] = (getObjectProperty(___arguments.context.pageData, 'one_year_co2_ef_67'));
  counter = 1;
  if ((JSON.stringify((getObjectProperty(___arguments.context.pageData, 'classes')))).length > 1) {
    console.log('passed if');
    tempClassesVariable = '';
    var repeat_end = (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'classes')))).length;
    for (var count = 0; count < repeat_end; count++) {
      if (counter == (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'classes')))).length) {
        tempClassesVariable = String(tempClassesVariable) + String((JSON.stringify((getObjectProperty(___arguments.context.pageData, 'classes')))).slice((counter - 1), counter));
      } else {
        tempClassesVariable = String(tempClassesVariable) + String(String((JSON.stringify((getObjectProperty(___arguments.context.pageData, 'classes')))).slice((counter - 1), counter)) + String(','));
        counter = counter + 1;
      }
    }
  } else {
    tempClassesVariable = (getObjectProperty(___arguments.context.pageData, 'classes'));
  }
  ___arguments.context.pageData['classesText'] = tempClassesVariable;
  ___arguments.context.pageData['cityText'] = (textToTitleCase(getObjectProperty(___arguments.context.pageData, 'phy_city')));
  ___arguments.context.pageData['stateText'] = (getObjectProperty(___arguments.context.pageData, 'phy_st'));
  ___arguments.context.pageData['countryText'] = (getObjectProperty(___arguments.context.pageData, 'phy_natn'));
  ___arguments.context.pageData['zipText'] = (getObjectProperty(___arguments.context.pageData, 'phy_zip'));
  ___arguments.context.pageData['foundedText'] = ([(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'add_date')))).slice(4, 6),'/',(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'add_date')))).slice(6, 8),'/',(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'add_date')))).slice(0, 4)].join(''));
  ___arguments.context.pageData['countryText'] = (getObjectProperty(___arguments.context.pageData, 'phy_natn'));
  ___arguments.context.pageData['averageMYText'] = (getObjectProperty(___arguments.context.pageData, 'avg_vehicle_age'));
  if ((getObjectProperty(___arguments.context.pageData, 'general_freight')) == 1) {
    ___arguments.context.pageData['generalFreightText'] = 'Yes';
  } else {
    ___arguments.context.pageData['generalFreightText'] = 'No';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'for_hire')) == 1) {
    ___arguments.context.pageData['forHireText'] = 'Yes';
  } else {
    ___arguments.context.pageData['forHireText'] = 'No';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'intermodal')) == 1) {
    ___arguments.context.pageData['intermodalText'] = 'Yes';
  } else {
    ___arguments.context.pageData['intermodalText'] = 'No';
  }
  ___arguments.context.pageData['reportedTrucksText'] = (getObjectProperty(___arguments.context.pageData, 'tot_pwr'));
  ___arguments.context.pageData['nbrDriversText'] = (getObjectProperty(___arguments.context.pageData, 'tot_drs'));
  ___arguments.context.pageData['calculatedTrucksText'] = (getObjectProperty(___arguments.context.pageData, 'calculated_tot_pwr'));
}


  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
  } else {
    userObject = (await Backendless.UserService.getCurrentUser(true));
    await setUserProperties();
    if (typeof (getObjectProperty(___arguments.context.pageData, 'name')) === 'undefined') {
      ___arguments.context.pageData['pageTitle'] = 'Carrier';
      ___arguments.context.pageData['dotNumberInputValue'] = '';
    } else {
      ___arguments.context.pageData['pageTitle'] = (String('Equipment summary for ') + String(textToTitleCase(getObjectProperty(___arguments.context.pageData, 'name'))));
      await updateSearchCount();
      await setCarrierProperties();
      if ((getObjectProperty(___arguments.context.pageData, 'dot_number')) < 2500000) {
        equipmentTableName = 'trucks_pull1';
      } else {
        equipmentTableName = 'trucks_pull2';
      }
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c11ea5c6a35b2be3860e9222791ce266', (await BackendlessUI.Functions.Custom['fn_4a82504ac5a365b515498484f6caee02'](equipmentTableName, (String('dot_number = ') + String(getObjectProperty(___arguments.context.pageData, 'dot_number'))), null, null, 'nbr_obs desc', null)));
    }
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/carrierEquipmentSummary_archive/components/f3d42bc37aba00457c6d25fefb770cf9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var classes, returnedCarrier, equipmentTableName, carrierSearch, userObject, tempClassesVariable, counter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}

/**
 * Describe this function...
 */
async function updateSearchCount() {
  userObject['currentSearches'] = ((getObjectProperty(userObject, 'currentSearches')) + 1);
  await Backendless.Data.of('Users').save(userObject, true);
  ___arguments.context.pageData['currentSearchesTextLabel'] = (String('Current searches: ') + String(getObjectProperty(userObject, 'currentSearches')));
}

/**
 * Describe this function...
 */
async function setCarrierProperties() {
  ___arguments.context.pageData['dotNumberInputValue'] = (getObjectProperty(returnedCarrier, 'dot_number'));
  ___arguments.context.pageData['dotText'] = (getObjectProperty(returnedCarrier, 'dot_number'));
  ___arguments.context.pageData['nameText'] = (textToTitleCase(getObjectProperty(returnedCarrier, 'name')));
  ___arguments.context.pageData['emissionsFactor82Text'] = (getObjectProperty(returnedCarrier, 'two_year_co2_ef_8'));
  ___arguments.context.pageData['efPercentile82Text'] = (getObjectProperty(returnedCarrier, 'ef_percentile_8'));
  ___arguments.context.pageData['emissionsFactorText'] = (getObjectProperty(returnedCarrier, 'two_year_co2_ef'));
  ___arguments.context.pageData['efPercentileText'] = (getObjectProperty(returnedCarrier, 'ef_percentile'));
  ___arguments.context.pageData['currentEFText'] = (getObjectProperty(returnedCarrier, 'current_best_estimate_8'));
  ___arguments.context.pageData['swEquivalentText'] = (getObjectProperty(returnedCarrier, 'smartway_class'));
  ___arguments.context.pageData['emissionsFactor32Text'] = (getObjectProperty(returnedCarrier, 'two_year_co2_ef_3'));
  ___arguments.context.pageData['emissionsFactor452Text'] = (getObjectProperty(returnedCarrier, 'two_year_co2_ef_45'));
  ___arguments.context.pageData['emissionsFactor672Text'] = (getObjectProperty(returnedCarrier, 'two_year_co2_ef_67'));
  ___arguments.context.pageData['emissionsFactor81Text'] = (getObjectProperty(returnedCarrier, 'one_year_co2_ef_8'));
  ___arguments.context.pageData['emissionsFactor31Text'] = (getObjectProperty(returnedCarrier, 'one_year_co2_ef_3'));
  ___arguments.context.pageData['emissionsFactor451Text'] = (getObjectProperty(returnedCarrier, 'one_year_co2_ef_45'));
  ___arguments.context.pageData['emissionsFactor671Text'] = (getObjectProperty(returnedCarrier, 'one_year_co2_ef_67'));
  counter = 1;
  if ((getObjectProperty(returnedCarrier, 'classes')).length > 1) {
    tempClassesVariable = '';
    var repeat_end = (getObjectProperty(returnedCarrier, 'classes')).length;
    for (var count = 0; count < repeat_end; count++) {
      if (counter == (getObjectProperty(returnedCarrier, 'classes')).length) {
        tempClassesVariable = String(tempClassesVariable) + String((getObjectProperty(returnedCarrier, 'classes')).slice((counter - 1), counter));
      } else {
        tempClassesVariable = String(tempClassesVariable) + String(String((getObjectProperty(returnedCarrier, 'classes')).slice((counter - 1), counter)) + String(','));
        counter = counter + 1;
      }
    }
  } else {
    tempClassesVariable = (getObjectProperty(returnedCarrier, 'classes'));
  }
  ___arguments.context.pageData['classesText'] = tempClassesVariable;
  ___arguments.context.pageData['cityText'] = (textToTitleCase(getObjectProperty(returnedCarrier, 'phy_city')));
  ___arguments.context.pageData['countryText'] = (getObjectProperty(returnedCarrier, 'phy_natn'));
  ___arguments.context.pageData['stateText'] = (getObjectProperty(returnedCarrier, 'phy_st'));
  ___arguments.context.pageData['zipText'] = (getObjectProperty(returnedCarrier, 'phy_zip'));
  ___arguments.context.pageData['foundedText'] = (getObjectProperty(returnedCarrier, 'add_date'));
  ___arguments.context.pageData['foundedText'] = ([(JSON.stringify((getObjectProperty(returnedCarrier, 'add_date')))).slice(5, 7),'/',(JSON.stringify((getObjectProperty(returnedCarrier, 'add_date')))).slice(7, 9),'/',(JSON.stringify((getObjectProperty(returnedCarrier, 'add_date')))).slice(1, 5)].join(''));
  ___arguments.context.pageData['averageMYText'] = (getObjectProperty(returnedCarrier, 'avg_vehicle_age'));
  if ((getObjectProperty(returnedCarrier, 'general_freight')) == 1) {
    ___arguments.context.pageData['generalFreightText'] = 'Yes';
  } else {
    ___arguments.context.pageData['generalFreightText'] = 'No';
  }
  if ((getObjectProperty(returnedCarrier, 'for_hire')) == 1) {
    ___arguments.context.pageData['forHireText'] = 'Yes';
  } else {
    ___arguments.context.pageData['forHireText'] = 'No';
  }
  if ((getObjectProperty(returnedCarrier, 'intermodal')) == 1) {
    ___arguments.context.pageData['intermodalText'] = 'Yes';
  } else {
    ___arguments.context.pageData['intermodalText'] = 'No';
  }
  ___arguments.context.pageData['reportedTrucksText'] = (getObjectProperty(returnedCarrier, 'tot_pwr'));
  ___arguments.context.pageData['nbrDriversText'] = (getObjectProperty(returnedCarrier, 'tot_drs'));
  ___arguments.context.pageData['calculatedTrucksText'] = (getObjectProperty(returnedCarrier, 'calculated_tot_pwr'));
}


  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
  } else {
    userObject = (await Backendless.UserService.getCurrentUser(true));
    try {
      if ((getObjectProperty(userObject, 'currentSearches')) > (getObjectProperty(userObject, 'maxSearches'))) {
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['label'] = 'Exceeded searches';
        await new Promise(r => setTimeout(r, 3000 || 0));
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
      } else if ((new Date((new Date()).getTime() + (86400 * 1000))) > ((getObjectProperty(userObject, 'licenseEndDate')).valueOf())) {
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['label'] = 'License expired';
        await new Promise(r => setTimeout(r, 3000 || 0));
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
      } else {
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['label'] = 'searching...';
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['disabled'] = true;
        if ((Number((getObjectProperty(___arguments.context.pageData, 'dotNumberInputValue')))) > 0) {
          carrierSearch = (await Backendless.Data.of('carrier_pull').find(Backendless.DataQueryBuilder.create().setWhereClause((String('dot_number = ') + String(getObjectProperty(___arguments.context.pageData, 'dotNumberInputValue')))).setPageSize(1)));
          returnedCarrier = carrierSearch[0];
          await updateSearchCount();
          ___arguments.context.pageData['pageTitle'] = (String('Details for ') + String(textToTitleCase(getObjectProperty(returnedCarrier, 'name'))));
          await setCarrierProperties();
          if ((getObjectProperty(___arguments.context.pageData, 'classesFilterValue')) == '') {
            classes = ' and class in(3,4,5,6,7,8) ';
          } else {
            classes = [' and class in(',(getObjectProperty(___arguments.context.pageData, 'classesFilterValue')),') '].join('');
          }
          if ((getObjectProperty(returnedCarrier, 'dot_number')) < 2500000) {
            equipmentTableName = 'trucks_pull1';
          } else {
            equipmentTableName = 'trucks_pull2';
          }
          (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c11ea5c6a35b2be3860e9222791ce266', (await BackendlessUI.Functions.Custom['fn_4a82504ac5a365b515498484f6caee02'](equipmentTableName, (['dot_number = ',(getObjectProperty(returnedCarrier, 'dot_number')),classes].join('')), null, null, 'nbr_obs desc', null)));
        }
      }

    } catch (error) {

    } finally {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['label'] = 'Search';
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['disabled'] = false;

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/carrierEquipmentSummary_archive/components/2826bc2226c99584ff1c27a142fbfc41/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landingPage', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/carrierEquipmentSummary_archive/components/9dcc3a9323e285016a1f999bd507ce52/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/changePasswordPage/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var user, error, currentPassword, username, logging, buttonLogin;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, currentPassword, true  ));

  } catch (error) {
    await setError(error);

  }
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  username = (getObjectProperty(___arguments.context.dataModel, 'username'));
  currentPassword = (getObjectProperty(___arguments.context.dataModel, 'currentPassword'));
  await updateButtonLogin(true);
  if (!(username && currentPassword)) {
    await setError((new Error('Username and Password must be filled')));
    await updateButtonLogin(false);
  } else if ((getObjectProperty(___arguments.context.dataModel, 'newPassword')) == '' || (typeof (getObjectProperty(___arguments.context.dataModel, 'newPassword')) === 'undefined')) {
    await setError((new Error('Please enter a new password')));
    await updateButtonLogin(false);
  } else if ((getObjectProperty(___arguments.context.dataModel, 'newPassword')) != (getObjectProperty(___arguments.context.dataModel, 'repeatNewPassword'))) {
    await setError((new Error('New passwords must match')));
    await updateButtonLogin(false);
  } else {
    try {
      await login();
      user['password'] = (getObjectProperty(___arguments.context.dataModel, 'newPassword'));
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2b01eaaf58b717b2f475981400819b99'))['background'] = 'green';
      await Backendless.UserService.update( new Backendless.User(user) );
      await setError((new Error('Successfully updated password, please log in with new credentials...')));
      await new Promise(r => setTimeout(r, 1500 || 0));
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);

    } catch (error) {
      await setError((new Error('Invalid login credentials')));
      await updateButtonLogin(false);

    }
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/changePasswordPage/components/6cca7a1c7973e7a76c256da5f1764c47/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customEmissionsFactor/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var tempClassesVariable, counter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setUserProperties() {
  ___arguments.context.pageData['userTextLabel'] = (String('User: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'name')));
  ___arguments.context.pageData['maxSearchesTextLabel'] = (String('Max monthly searches: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'maxSearches')));
  ___arguments.context.pageData['currentSearchesTextLabel'] = (String('Current searches: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'currentSearches')));
  ___arguments.context.pageData['licenseEndDateTextLabel'] = (String('License valid through: ') + String((new Date(((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'licenseEndDate')).valueOf()))).toLocaleDateString()));
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}

/**
 * Describe this function...
 */
async function setCarrierProperties() {
  ___arguments.context.pageData['dotNumberInputValue'] = (getObjectProperty(___arguments.context.pageData, 'dot_number'));
  ___arguments.context.pageData['dotText'] = (getObjectProperty(___arguments.context.pageData, 'dot_number'));
  ___arguments.context.pageData['nameText'] = (textToTitleCase(getObjectProperty(___arguments.context.pageData, 'name')));
  ___arguments.context.pageData['emissionsFactor82Text'] = (getObjectProperty(___arguments.context.pageData, 'two_year_co2_ef_8'));
  ___arguments.context.pageData['efPercentile82Text'] = (getObjectProperty(___arguments.context.pageData, 'ef_percentile_8'));
  ___arguments.context.pageData['emissionsFactorText'] = (getObjectProperty(___arguments.context.pageData, 'two_year_co2_ef'));
  ___arguments.context.pageData['efPercentileText'] = (getObjectProperty(___arguments.context.pageData, 'ef_percentile'));
  ___arguments.context.pageData['emissionsFactor32Text'] = (getObjectProperty(___arguments.context.pageData, 'two_year_co2_ef_3'));
  ___arguments.context.pageData['emissionsFactor452Text'] = (getObjectProperty(___arguments.context.pageData, 'two_year_co2_ef_45'));
  ___arguments.context.pageData['emissionsFactor672Text'] = (getObjectProperty(___arguments.context.pageData, 'two_year_co2_ef_67'));
  ___arguments.context.pageData['emissionsFactor81Text'] = (getObjectProperty(___arguments.context.pageData, 'one_year_co2_ef_8'));
  ___arguments.context.pageData['emissionsFactor31Text'] = (getObjectProperty(___arguments.context.pageData, 'one_year_co2_ef_3'));
  ___arguments.context.pageData['emissionsFactor451Text'] = (getObjectProperty(___arguments.context.pageData, 'one_year_co2_ef_45'));
  ___arguments.context.pageData['emissionsFactor671Text'] = (getObjectProperty(___arguments.context.pageData, 'one_year_co2_ef_67'));
  counter = 1;
  if ((getObjectProperty(___arguments.context.pageData, 'classes')).length > 1) {
    tempClassesVariable = '';
    var repeat_end = (getObjectProperty(___arguments.context.pageData, 'classes')).length;
    for (var count = 0; count < repeat_end; count++) {
      if (counter == (getObjectProperty(___arguments.context.pageData, 'classes')).length) {
        tempClassesVariable = String(tempClassesVariable) + String((getObjectProperty(___arguments.context.pageData, 'classes')).slice((counter - 1), counter));
      } else {
        tempClassesVariable = String(tempClassesVariable) + String(String((getObjectProperty(___arguments.context.pageData, 'classes')).slice((counter - 1), counter)) + String(','));
        counter = counter + 1;
      }
    }
  } else {
    tempClassesVariable = (getObjectProperty(___arguments.context.pageData, 'classes'));
  }
  ___arguments.context.pageData['classesText'] = tempClassesVariable;
  ___arguments.context.pageData['cityText'] = (textToTitleCase(getObjectProperty(___arguments.context.pageData, 'phy_city')));
  ___arguments.context.pageData['stateText'] = (getObjectProperty(___arguments.context.pageData, 'phy_st'));
  ___arguments.context.pageData['zipText'] = (getObjectProperty(___arguments.context.pageData, 'phy_zip'));
  ___arguments.context.pageData['foundedText'] = ([(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'add_date')))).slice(4, 6),'/',(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'add_date')))).slice(6, 8),'/',(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'add_date')))).slice(0, 4)].join(''));
  ___arguments.context.pageData['countryText'] = (getObjectProperty(___arguments.context.pageData, 'phy_natn'));
  ___arguments.context.pageData['averageMYText'] = (getObjectProperty(___arguments.context.pageData, 'avg_vehicle_age'));
  if ((getObjectProperty(___arguments.context.pageData, 'general_freight')) == 1) {
    ___arguments.context.pageData['generalFreightText'] = 'Yes';
  } else {
    ___arguments.context.pageData['generalFreightText'] = 'No';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'for_hire')) == 1) {
    ___arguments.context.pageData['forHireText'] = 'Yes';
  } else {
    ___arguments.context.pageData['forHireText'] = 'No';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'intermodal')) == 1) {
    ___arguments.context.pageData['intermodalText'] = 'Yes';
  } else {
    ___arguments.context.pageData['intermodalText'] = 'No';
  }
  ___arguments.context.pageData['reportedTrucksText'] = (getObjectProperty(___arguments.context.pageData, 'tot_pwr'));
  ___arguments.context.pageData['nbrDriversText'] = (getObjectProperty(___arguments.context.pageData, 'tot_drs'));
  ___arguments.context.pageData['calculatedTrucksText'] = (getObjectProperty(___arguments.context.pageData, 'calculated_tot_pwr'));
}


  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
  } else {
    await setUserProperties();
    ___arguments.context.pageData['pageTitle'] = 'Custom emissions factor';
    ___arguments.context.pageData['dotNumberInputValue'] = '';
    ___arguments.context.pageData['minDateValue'] = (new Date((new Date()).getTime() - (31449600 * 1000)));
    ___arguments.context.pageData['maxDateValue'] = (new Date());
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/customEmissionsFactor/components/f3d42bc37aba00457c6d25fefb770cf9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var userObject, j, classes, equipmentDetailTableName, calculatedEF, returnedCarrier, carrierSearch;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function updateSearchCount() {
  userObject['currentSearches'] = ((getObjectProperty(userObject, 'currentSearches')) + 1);
  await Backendless.Data.of('Users').save(userObject, true);
  ___arguments.context.pageData['currentSearchesTextLabel'] = (String('Current searches: ') + String(getObjectProperty(userObject, 'currentSearches')));
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}

/**
 * Describe this function...
 */
async function getTableName() {
  if ((getObjectProperty(returnedCarrier, 'dot_number')) > 0 && (getObjectProperty(returnedCarrier, 'dot_number')) <= 300000) {
    equipmentDetailTableName = 'trucks_detail1';
  } else if ((getObjectProperty(returnedCarrier, 'dot_number')) > 300000 && (getObjectProperty(returnedCarrier, 'dot_number')) <= 1000000) {
    equipmentDetailTableName = 'trucks_detail2';
  } else if ((getObjectProperty(returnedCarrier, 'dot_number')) > 1000000 && (getObjectProperty(returnedCarrier, 'dot_number')) <= 2000000) {
    equipmentDetailTableName = 'trucks_detail3';
  } else if ((getObjectProperty(returnedCarrier, 'dot_number')) > 2000000 && (getObjectProperty(returnedCarrier, 'dot_number')) <= 2900000) {
    equipmentDetailTableName = 'trucks_detail4';
  } else if ((getObjectProperty(returnedCarrier, 'dot_number')) > 2900000 && (getObjectProperty(returnedCarrier, 'dot_number')) <= 3500000) {
    equipmentDetailTableName = 'trucks_detail5';
  } else if ((getObjectProperty(returnedCarrier, 'dot_number')) > 3500000) {
    equipmentDetailTableName = 'trucks_detail6';
  }
}


  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
  } else {
    userObject = (await Backendless.UserService.getCurrentUser(true));
    try {
      if ((getObjectProperty(userObject, 'currentSearches')) > (getObjectProperty(userObject, 'maxSearches'))) {
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['label'] = 'Exceeded searches';
        await new Promise(r => setTimeout(r, 3000 || 0));
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
      } else if ((new Date((new Date()).getTime() + (86400 * 1000))) > ((getObjectProperty(userObject, 'licenseEndDate')).valueOf())) {
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['label'] = 'License expired';
        await new Promise(r => setTimeout(r, 3000 || 0));
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
      } else {
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['label'] = 'searching...';
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['disabled'] = true;
        if ((Number((getObjectProperty(___arguments.context.pageData, 'dotNumberInputValue')))) > 0) {
          carrierSearch = (await Backendless.Data.of('carrier_pull').find(Backendless.DataQueryBuilder.create().setWhereClause((String('dot_number = ') + String(getObjectProperty(___arguments.context.pageData, 'dotNumberInputValue')))).setProperties('dot_number, name').setPageSize(1)));
          returnedCarrier = carrierSearch[0];
          await updateSearchCount();
          ___arguments.context.pageData['pageTitle'] = (String('Custom emissions factor for ') + String(textToTitleCase(getObjectProperty(returnedCarrier, 'name'))));
          classes = [' and class in(',(getObjectProperty(___arguments.context.pageData, 'classSelectValue')),') '].join('');
          await getTableName();
          if (typeof (getObjectProperty(___arguments.context.pageData, 'distinctCheckValue')) === 'undefined') {
            ___arguments.context.pageData['distinctCheckValue'] = false;
          }
          calculatedEF = (await Backendless.Data.of(equipmentDetailTableName).find(Backendless.DataQueryBuilder.create().setWhereClause((['dot_number = ',(getObjectProperty(___arguments.context.pageData, 'dotNumberInputValue')),classes,'and observation_date >= ',((getObjectProperty(___arguments.context.pageData, 'minDateValue')).valueOf()),' and observation_date <= ',((getObjectProperty(___arguments.context.pageData, 'maxDateValue')).valueOf())].join(''))).setProperties('avg(co2_ef)').setPageSize(1)));
          ___arguments.context.pageData['overallEFValue'] = (getObjectProperty((calculatedEF[0]), 'avg'));
          (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c11ea5c6a35b2be3860e9222791ce266', (await Backendless.Data.of(equipmentDetailTableName).find(Backendless.DataQueryBuilder.create().setWhereClause((['dot_number = ',(getObjectProperty(___arguments.context.pageData, 'dotNumberInputValue')),classes,'and observation_date >= ',((getObjectProperty(___arguments.context.pageData, 'minDateValue')).valueOf()),' and observation_date <= ',((getObjectProperty(___arguments.context.pageData, 'maxDateValue')).valueOf())].join(''))).setProperties('unit_vin, make, fuel_type, class, model, model_year, co2_ef, observation_date').setSortBy('observation_date desc').setPageSize(100).setDistinct((getObjectProperty(___arguments.context.pageData, 'distinctCheckValue'))))));
        }
      }

    } catch (error) {

    } finally {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['label'] = 'Search';
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f3d42bc37aba00457c6d25fefb770cf9'))['disabled'] = false;

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customEmissionsFactor/components/2826bc2226c99584ff1c27a142fbfc41/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landingPage', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customEmissionsFactor/components/9dcc3a9323e285016a1f999bd507ce52/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dataDefinitions/components/d772358a3d4c3526644b02155f1e55ab/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landingPage', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/emissionsFactorDataDefinitions/components/d772358a3d4c3526644b02155f1e55ab/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landingPage', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/equipmentDataDefinitions/components/d772358a3d4c3526644b02155f1e55ab/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landingPage', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/indexDataDefinitions/components/d772358a3d4c3526644b02155f1e55ab/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landingPage', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landingPage/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
      ___arguments.context.appData['carrierTableName'] = 'carrier_pull';
  ___arguments.context.appData['pageOffset'] = 100;

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/landingPage/components/b4a9268de5ceada612ca449d110cdb05/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('mainCarrierPage', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landingPage/components/76e866124972ab395fd354c9f346cd37/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('carrierEquipmentSummary', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landingPage/components/5d3c1546d7a5b0aea353b933a6df21a2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('customEmissionsFactor', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/loginPage/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var user, error, password, username, stayLoggedIn, logging, buttonLogin;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, password, stayLoggedIn  ));

  } catch (error) {
    await setError(error);

  }
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  username = (getObjectProperty(___arguments.context.dataModel, 'username'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  stayLoggedIn = (getObjectProperty(___arguments.context.dataModel, 'stayLoggedIn'));
  if (username && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Username and Password must be filled')));
  }
  if (user) {
    if ((new Date((new Date()).getTime() + (86400 * 1000))) > ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'licenseEndDate')).valueOf())) {
      await setError((new Error('License has expired. Please contact support.')));
    } else {
      ___arguments.context.appData['currentUser'] = user;
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landingPage', undefined);
    }
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/mainCarrierPage/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var carrierTableName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.appData['carrierTableName'] = 'carrier_pull';
  ___arguments.context.appData['pageOffset'] = 100;
  ___arguments.context.appData['loadingData'] = true;
  carrierTableName = (getObjectProperty(___arguments.context.appData, 'carrierTableName'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('365b6cf60c7ea95d6769727e5ca2f771', (await Backendless.Data.of(carrierTableName).find(Backendless.DataQueryBuilder.create().setWhereClause('two_year_co2_ef_8 is not null').setPageSize(100))));

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var testing;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setUserProperties() {
  ___arguments.context.pageData['userTextLabel'] = (String('User: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'name')));
  ___arguments.context.pageData['maxSearchesTextLabel'] = (String('Max monthly searches: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'maxSearches')));
  ___arguments.context.pageData['currentSearchesTextLabel'] = (String('Current searches: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'currentSearches')));
  ___arguments.context.pageData['licenseEndDateTextLabel'] = (String('License valid through: ') + String((new Date(((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'licenseEndDate')).valueOf()))).toLocaleDateString()));
}


  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
  } else {
    testing = false;
    if (testing) {
      ___arguments.context.appData['appLocation'] = 'https://scenicsquare.backendless.app/';
    } else {
      ___arguments.context.appData['appLocation'] = 'https://www.sustainlog.app/';
    }
    ___arguments.context.pageData['filtersVisible'] = false;
    ___arguments.context.pageData['forHireFilter'] = false;
    ___arguments.context.pageData['searchLabel'] = 'Search';
    await setUserProperties();
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/mainCarrierPage/components/6928a60d0c519f61d4f69f92e7d91ae8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var userObject, dotWhereStatement, sortByStatement, carrierTableName, filtersWhereStatement, subText, j, firstDOTNumber, cleanedList, dotNumbers, cleanedStatesList, counter, tempStates;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function updateSearchCount() {
  userObject['currentSearches'] = ((getObjectProperty(userObject, 'currentSearches')) + 1);
  await Backendless.Data.of('Users').save(userObject, true);
  ___arguments.context.pageData['currentSearchesTextLabel'] = (String('Current searches: ') + String(getObjectProperty(userObject, 'currentSearches')));
}

function subsequenceFromStartFromEnd(sequence, at1, at2) {
  var start = at1;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}

/**
 * Describe this function...
 */
async function formatDOTInputNumbers() {
  dotNumbers = [];
  dotNumbers = (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'inputDOTNumbers')))).split('\\n');
  if (dotNumbers.length > 300) {
    dotNumbers = dotNumbers.slice(0, 300);
  }
  cleanedList = subsequenceFromStartFromEnd(String(dotNumbers.join(', ')), 1, 1).split(', ');
  dotWhereStatement = 'dot_number in(';
  subText = '';
  firstDOTNumber = true;
  for (var j_index in cleanedList) {
    j = cleanedList[j_index];
    if ((Number(j)) > 0 && firstDOTNumber == true) {
      subText = String(subText) + String(j);
      firstDOTNumber = false;
    } else if ((Number(j)) > 0 && firstDOTNumber == false) {
      subText = String(subText) + String(String(', ') + String(j));
    }
  }
  dotWhereStatement = String(dotWhereStatement) + String(String(subText) + String(')'));
}

/**
 * Describe this function...
 */
async function formatFiltersStatement() {
  filtersWhereStatement = '';
  if (getObjectProperty(___arguments.context.pageData, 'forHireFilter')) {
    filtersWhereStatement = ' and for_hire = 1 ';
  }
  if (getObjectProperty(___arguments.context.pageData, 'intermodalFilter')) {
    filtersWhereStatement = String(filtersWhereStatement) + String(' and intermodal = 1 ');
  }
  if (getObjectProperty(___arguments.context.pageData, 'electricFilter')) {
    filtersWhereStatement = String(filtersWhereStatement) + String(' and electric = 1 ');
  }
  if (getObjectProperty(___arguments.context.pageData, 'electric8Filter')) {
    filtersWhereStatement = String(filtersWhereStatement) + String(' and electric_8 = 1 ');
  }
  if (getObjectProperty(___arguments.context.pageData, 'naturalGasFilter')) {
    filtersWhereStatement = String(filtersWhereStatement) + String(' and natural_gas = 1 ');
  }
  if (getObjectProperty(___arguments.context.pageData, 'naturalGas8Filter')) {
    filtersWhereStatement = String(filtersWhereStatement) + String(' and natural_gas_8 = 1 ');
  }
  if (getObjectProperty(___arguments.context.pageData, 'generalFreightFilter')) {
    filtersWhereStatement = String(filtersWhereStatement) + String(' and general_freight = 1 ');
  }
  if ((Number((getObjectProperty(___arguments.context.pageData, 'EFLEValue8')))) != 1900) {
    filtersWhereStatement = String(filtersWhereStatement) + String(String(' and two_year_co2_ef_8 <= ') + String(getObjectProperty(___arguments.context.pageData, 'EFLEValue8')));
  }
  if ((Number((getObjectProperty(___arguments.context.pageData, 'EFLEValue')))) != 1900) {
    filtersWhereStatement = String(filtersWhereStatement) + String(String(' and two_year_co2_ef <= ') + String(getObjectProperty(___arguments.context.pageData, 'EFLEValue')));
  }
  if ((Number((getObjectProperty(___arguments.context.pageData, 'PercentileGEValue')))) > 0) {
    filtersWhereStatement = String(filtersWhereStatement) + String(String(' and ef_percentile_8 >= ') + String(getObjectProperty(___arguments.context.pageData, 'PercentileGEValue')));
  }
  if ((Number((getObjectProperty(___arguments.context.pageData, 'NbrTrucksGEValue')))) > 0) {
    filtersWhereStatement = String(filtersWhereStatement) + String(String(' and calculated_tot_pwr >= ') + String(getObjectProperty(___arguments.context.pageData, 'NbrTrucksGEValue')));
  }
  if ((Number((getObjectProperty(___arguments.context.pageData, 'NbrTrucksLEValue')))) > 0) {
    filtersWhereStatement = String(filtersWhereStatement) + String(String(' and calculated_tot_pwr <= ') + String(getObjectProperty(___arguments.context.pageData, 'NbrTrucksLEValue')));
  }
  if ((Number((getObjectProperty(___arguments.context.pageData, 'swEquivalentValue')))) != 6) {
    filtersWhereStatement = String(filtersWhereStatement) + String(String(' and smartway_class <= ') + String(getObjectProperty(___arguments.context.pageData, 'swEquivalentValue')));
  }
  if (!((typeof (getObjectProperty(___arguments.context.pageData, 'stateValue')) === 'undefined') || (getObjectProperty(___arguments.context.pageData, 'stateValue')) == '')) {
    if ((getObjectProperty(___arguments.context.pageData, 'stateValue')).indexOf(',') + 1 > 0) {
      tempStates = [];
      cleanedStatesList = '';
      counter = 0;
      tempStates = (getObjectProperty(___arguments.context.pageData, 'stateValue')).split(',');
      for (var j_index2 in tempStates) {
        j = tempStates[j_index2];
        if (counter == tempStates.length - 1) {
          j = ['\'',j.trim(),'\''].join('');
          cleanedStatesList = String(cleanedStatesList) + String(j);
        } else {
          counter = counter + 1;
          j = ['\'',j.trim(),'\','].join('');
          cleanedStatesList = String(cleanedStatesList) + String(j);
        }
      }
      filtersWhereStatement = String(filtersWhereStatement) + String([' and phy_st in(',cleanedStatesList,')'].join(''));
    } else {
      filtersWhereStatement = String(filtersWhereStatement) + String([' and phy_st in(\'',(getObjectProperty(___arguments.context.pageData, 'stateValue')),'\')'].join(''));
    }
  }
  if (!((typeof (getObjectProperty(___arguments.context.pageData, 'nameValue')) === 'undefined') || (getObjectProperty(___arguments.context.pageData, 'nameeValue')) == '')) {
    filtersWhereStatement = String(filtersWhereStatement) + String([' and name like \'%',(getObjectProperty(___arguments.context.pageData, 'nameValue')),'%\''].join(''));
  }
}


  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
  } else {
    userObject = (await Backendless.UserService.getCurrentUser(true));
    try {
      if ((getObjectProperty(userObject, 'currentSearches')) > (getObjectProperty(userObject, 'maxSearches'))) {
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6928a60d0c519f61d4f69f92e7d91ae8'))['label'] = 'Exceeded searches';
        await new Promise(r => setTimeout(r, 3000 || 0));
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
      } else if ((new Date((new Date()).getTime() + (86400 * 1000))) > ((getObjectProperty(userObject, 'licenseEndDate')).valueOf())) {
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6928a60d0c519f61d4f69f92e7d91ae8'))['label'] = 'License expired';
        await new Promise(r => setTimeout(r, 3000 || 0));
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);
      } else {
        await updateSearchCount();
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6928a60d0c519f61d4f69f92e7d91ae8'))['label'] = 'searching...';
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6928a60d0c519f61d4f69f92e7d91ae8'))['disabled'] = true;
        ___arguments.context.appData['pageOffset'] = 100;
        ___arguments.context.appData['loadingData'] = true;
        carrierTableName = (getObjectProperty(___arguments.context.appData, 'carrierTableName'));
        if ((getObjectProperty(___arguments.context.pageData, 'filtersVisible')) == false) {
          if ((typeof (getObjectProperty(___arguments.context.pageData, 'inputDOTNumbers')) === 'undefined') || (getObjectProperty(___arguments.context.pageData, 'inputDOTNumbers')) == '') {
            (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('365b6cf60c7ea95d6769727e5ca2f771', (await Backendless.Data.of(carrierTableName).find(Backendless.DataQueryBuilder.create().setPageSize(100))));
          } else {
            await formatDOTInputNumbers();
            (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('365b6cf60c7ea95d6769727e5ca2f771', (await BackendlessUI.Functions.Custom['fn_4a82504ac5a365b515498484f6caee02'](carrierTableName, dotWhereStatement, null, null, null, null)));
            ___arguments.context.appData['loadingData'] = false;
          }
          ___arguments.context.appData['pagingWhereStatement'] = dotWhereStatement;
        } else if ((getObjectProperty(___arguments.context.pageData, 'filtersVisible')) == true) {
          await formatFiltersStatement();
          sortByStatement = '';
          sortByStatement = (getObjectProperty(___arguments.context.pageData, 'sortByValue'));
          ___arguments.context.appData['pagingSortStatement'] = sortByStatement;
          if ((typeof (getObjectProperty(___arguments.context.pageData, 'inputDOTNumbers')) === 'undefined') || (getObjectProperty(___arguments.context.pageData, 'inputDOTNumbers')) == '') {
            dotWhereStatement = String(dotWhereStatement) + String(filtersWhereStatement);
            dotWhereStatement = filtersWhereStatement.slice(4, filtersWhereStatement.length);
            (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('365b6cf60c7ea95d6769727e5ca2f771', (await Backendless.Data.of(carrierTableName).find(Backendless.DataQueryBuilder.create().setWhereClause(dotWhereStatement).setSortBy(sortByStatement).setPageSize(100))));
            if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('365b6cf60c7ea95d6769727e5ca2f771')).length < 100) {
              ___arguments.context.appData['loadingData'] = false;
            }
            ___arguments.context.appData['pagingWhereStatement'] = dotWhereStatement;
          } else {
            await formatDOTInputNumbers();
            dotWhereStatement = String(dotWhereStatement) + String(filtersWhereStatement);
            (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('365b6cf60c7ea95d6769727e5ca2f771', (await BackendlessUI.Functions.Custom['fn_4a82504ac5a365b515498484f6caee02'](carrierTableName, dotWhereStatement, null, null, sortByStatement, null)));
            ___arguments.context.appData['loadingData'] = false;
            ___arguments.context.appData['pagingWhereStatement'] = dotWhereStatement;
          }
        }
      }

    } catch (error) {

    } finally {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6928a60d0c519f61d4f69f92e7d91ae8'))['label'] = 'Search';
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6928a60d0c519f61d4f69f92e7d91ae8'))['disabled'] = false;

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/mainCarrierPage/components/c546702a9f358c82bcfda8ce09acac52/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedCarrier'] = ___arguments.context.getComponentDataStoreByUid('4b199da145592bf6f9ac0bfb26350b4c');
  if ((getObjectProperty(___arguments.context.appData, 'appLocation')) == 'https://www.sustainlog.app/') {
    (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String(getObjectProperty(___arguments.context.appData, 'appLocation')) + String('?page=carrierEquipmentSummary')), true, (getObjectProperty(___arguments.context.pageData, 'selectedCarrier')));
  } else {
    (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String(getObjectProperty(___arguments.context.appData, 'appLocation')) + String('api/files/ui-builder/containers/default/index.html?page=carrierEquipmentSummary')), true, (getObjectProperty(___arguments.context.pageData, 'selectedCarrier')));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/mainCarrierPage/components/182b38c1a24f9b3fd3781c8b3698acb2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var carrierTableName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  carrierTableName = (getObjectProperty(___arguments.context.appData, 'carrierTableName'));
  if (getObjectProperty(___arguments.context.appData, 'loadingData')) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('365b6cf60c7ea95d6769727e5ca2f771', (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('365b6cf60c7ea95d6769727e5ca2f771')).concat((await Backendless.Data.of(carrierTableName).find(Backendless.DataQueryBuilder.create().setWhereClause((getObjectProperty(___arguments.context.appData, 'pagingWhereStatement'))).setSortBy((getObjectProperty(___arguments.context.appData, 'pagingSortStatement'))).setPageSize(100).setOffset((getObjectProperty(___arguments.context.appData, 'pageOffset'))))))));
    if ((await Backendless.Data.of(carrierTableName).find(Backendless.DataQueryBuilder.create().setWhereClause((getObjectProperty(___arguments.context.appData, 'pagingWhereStatement'))).setSortBy((getObjectProperty(___arguments.context.appData, 'pagingSortStatement'))).setPageSize(100).setOffset((getObjectProperty(___arguments.context.appData, 'pageOffset'))))).length < 100) {
      ___arguments.context.appData['loadingData'] = false;
    }
    ___arguments.context.appData['pageOffset'] = ((getObjectProperty(___arguments.context.appData, 'pageOffset')) + 100);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/mainCarrierPage/components/64997ae718bae02541bc133636c6e7bf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedCarrier'] = ___arguments.context.getComponentDataStoreByUid('4b199da145592bf6f9ac0bfb26350b4c');
  if ((getObjectProperty(___arguments.context.appData, 'appLocation')) == 'https://www.sustainlog.app/') {
    (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String(getObjectProperty(___arguments.context.appData, 'appLocation')) + String('?page=carrierEquipmentSummary')), true, (getObjectProperty(___arguments.context.pageData, 'selectedCarrier')));
  } else {
    (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((String(getObjectProperty(___arguments.context.appData, 'appLocation')) + String('api/files/ui-builder/containers/default/index.html?page=carrierEquipmentSummary')), true, (getObjectProperty(___arguments.context.pageData, 'selectedCarrier')));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/mainCarrierPage/components/26d1b259a9be1016f64620431241ea9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('loginPage', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/mainCarrierPage/components/364a6e8bc6ee7a747bc4ecb885460933/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landingPage', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/mainCarrierPage/components/b368e8b6789d4e2632a1b73d7c7212b2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var fileName, listForCSV, csvFileURL, userObject, tempObjectForCSV, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  userObject = (await Backendless.UserService.getCurrentUser(false));
  listForCSV = [];
  var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('365b6cf60c7ea95d6769727e5ca2f771'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    tempObjectForCSV = ({ [`DOT number`]: (getObjectProperty(j, 'dot_number')),[`Name`]: (getObjectProperty(j, 'name')),[`Class 8 emissions factor`]: (getObjectProperty(j, 'two_year_co2_ef_8')),[`Class 8 EF percentile`]: (getObjectProperty(j, 'ef_percentile_8')),[`Overall emissions factor`]: (getObjectProperty(j, 'two_year_co2_ef')),[`Overall EF percentile`]: (getObjectProperty(j, 'ef_percentile')),[`Current best estimate`]: (getObjectProperty(j, 'current_best_estimate_8')),[`SmartWay equivalent`]: (getObjectProperty(j, 'smartway_class')),[`Avg truck model year`]: (getObjectProperty(j, 'avg_vehicle_age')),[`# trucks`]: (getObjectProperty(j, 'tot_pwr')),[`Truck classes`]: (getObjectProperty(j, 'classes')),[`For hire`]: (getObjectProperty(j, 'for_hire')),[`Intermodal`]: (getObjectProperty(j, 'intermodal')),[`One year overall emissions factor`]: (getObjectProperty(j, 'one_year_co2_ef')),[`One year class 8 emissions factor`]: (getObjectProperty(j, 'one_year_co2_ef_8')),[`Class 8 current best estimate`]: (getObjectProperty(j, 'current_best_estimate_8')),[`Two year class 3 emissions factor`]: (getObjectProperty(j, 'two_year_co2_ef_3')),[`Two year classes 4-5 emissions factor`]: (getObjectProperty(j, 'two_year_co2_ef_45')),[`Two year classes 6-7 emissions factor`]: (getObjectProperty(j, 'two_year_co2_ef_67')),[`One year class 3 emissions factor`]: (getObjectProperty(j, 'one_year_co2_ef_3')),[`One year class 4-5 emissions factor`]: (getObjectProperty(j, 'one_year_co2_ef_45')),[`One year class 6-7 emissions factor`]: (getObjectProperty(j, 'one_year_co2_ef_67')) });
    addItemToList(listForCSV, tempObjectForCSV);
  }
  fileName = ['downloads/',(getObjectProperty(userObject, 'email')),'.csv'].join('');
  csvFileURL = (await Backendless.Request.post(`${Backendless.appPath}/services/CSVAdapter/list2csv`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'filePath': fileName,'data': listForCSV,'csvOptions': ({ [`header`]: true }) }));
  if ((getObjectProperty(___arguments.context.appData, 'appLocation')) == 'https://www.sustainlog.app/') {
    (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })(([(getObjectProperty(___arguments.context.appData, 'appLocation')),'api/files/',fileName].join('')), true, undefined);
  } else {
    (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })(([(getObjectProperty(___arguments.context.appData, 'appLocation')),'api/files/',fileName].join('')), true, undefined);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/verifiedCarrier/components/2826bc2226c99584ff1c27a142fbfc41/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landingPage', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/verifiedCarrier/components/765d211203cc98ba907f72b2b4271979/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var carrier, carrierDetails, counter, dotNumber, passCode, returnedCarrierDetails, tempClassesVariable;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}

/**
 * Describe this function...
 */
async function setCarrierProperties() {
  ___arguments.context.pageData['dotText'] = (getObjectProperty(returnedCarrierDetails, 'dot_number'));
  ___arguments.context.pageData['nameText'] = (getObjectProperty(returnedCarrierDetails, 'name'));
  if ((Number((getObjectProperty(returnedCarrierDetails, 'ef_percentile_8')))) >= 90) {
    ___arguments.context.pageData['ratingText'] = 'Excellent';
  } else if ((Number((getObjectProperty(returnedCarrierDetails, 'ef_percentile_8')))) >= 75) {
    ___arguments.context.pageData['ratingText'] = 'Very good';
  } else if ((Number((getObjectProperty(returnedCarrierDetails, 'ef_percentile_8')))) >= 50) {
    ___arguments.context.pageData['ratingText'] = 'Good';
  } else {
    ___arguments.context.pageData['ratingText'] = 'Not rated';
  }
  ___arguments.context.pageData['emissionsFactor82Text'] = (getObjectProperty(returnedCarrierDetails, 'two_year_co2_ef_8'));
  ___arguments.context.pageData['efPercentile82Text'] = (getObjectProperty(returnedCarrierDetails, 'ef_percentile_8'));
  ___arguments.context.pageData['emissionsFactorText'] = (getObjectProperty(returnedCarrierDetails, 'two_year_co2_ef'));
  ___arguments.context.pageData['efPercentileText'] = (getObjectProperty(returnedCarrierDetails, 'ef_percentile'));
  ___arguments.context.pageData['swEquivalentText'] = (getObjectProperty(returnedCarrierDetails, 'smartway_class'));
  ___arguments.context.pageData['emissionsFactor32Text'] = (getObjectProperty(returnedCarrierDetails, 'two_year_co2_ef_3'));
  ___arguments.context.pageData['emissionsFactor452Text'] = (getObjectProperty(returnedCarrierDetails, 'two_year_co2_ef_45'));
  ___arguments.context.pageData['emissionsFactor672Text'] = (getObjectProperty(returnedCarrierDetails, 'two_year_co2_ef_67'));
  ___arguments.context.pageData['emissionsFactor81Text'] = (getObjectProperty(returnedCarrierDetails, 'one_year_co2_ef_8'));
  ___arguments.context.pageData['emissionsFactor31Text'] = (getObjectProperty(returnedCarrierDetails, 'one_year_co2_ef_3'));
  ___arguments.context.pageData['emissionsFactor451Text'] = (getObjectProperty(returnedCarrierDetails, 'one_year_co2_ef_45'));
  ___arguments.context.pageData['emissionsFactor671Text'] = (getObjectProperty(returnedCarrierDetails, 'one_year_co2_ef_67'));
  counter = 1;
  if ((getObjectProperty(returnedCarrierDetails, 'classes')).length > 1) {
    tempClassesVariable = '';
    var repeat_end = (getObjectProperty(returnedCarrierDetails, 'classes')).length;
    for (var count = 0; count < repeat_end; count++) {
      if (counter == (getObjectProperty(returnedCarrierDetails, 'classes')).length) {
        tempClassesVariable = String(tempClassesVariable) + String((getObjectProperty(returnedCarrierDetails, 'classes')).slice((counter - 1), counter));
      } else {
        tempClassesVariable = String(tempClassesVariable) + String(String((getObjectProperty(returnedCarrierDetails, 'classes')).slice((counter - 1), counter)) + String(','));
        counter = counter + 1;
      }
    }
  } else {
    tempClassesVariable = (getObjectProperty(returnedCarrierDetails, 'classes'));
  }
  ___arguments.context.pageData['classesText'] = tempClassesVariable;
  ___arguments.context.pageData['cityText'] = (textToTitleCase(getObjectProperty(returnedCarrierDetails, 'phy_city')));
  ___arguments.context.pageData['countryText'] = (getObjectProperty(returnedCarrierDetails, 'phy_natn'));
  ___arguments.context.pageData['stateText'] = (getObjectProperty(returnedCarrierDetails, 'phy_st'));
  ___arguments.context.pageData['zipText'] = (getObjectProperty(returnedCarrierDetails, 'phy_zip'));
  ___arguments.context.pageData['averageMYText'] = (getObjectProperty(returnedCarrierDetails, 'avg_vehicle_age'));
  if ((getObjectProperty(returnedCarrierDetails, 'general_freight')) == 1) {
    ___arguments.context.pageData['generalFreightText'] = 'Yes';
  } else {
    ___arguments.context.pageData['generalFreightText'] = 'No';
  }
  if ((getObjectProperty(returnedCarrierDetails, 'for_hire')) == 1) {
    ___arguments.context.pageData['forHireText'] = 'Yes';
  } else {
    ___arguments.context.pageData['forHireText'] = 'No';
  }
  if ((getObjectProperty(returnedCarrierDetails, 'intermodal')) == 1) {
    ___arguments.context.pageData['intermodalText'] = 'Yes';
  } else {
    ___arguments.context.pageData['intermodalText'] = 'No';
  }
  ___arguments.context.pageData['reportedTrucksText'] = (getObjectProperty(returnedCarrierDetails, 'tot_pwr'));
  ___arguments.context.pageData['nbrDriversText'] = (getObjectProperty(returnedCarrierDetails, 'tot_drs'));
  ___arguments.context.pageData['calculatedTrucksText'] = (getObjectProperty(returnedCarrierDetails, 'calculated_tot_pwr'));
}


  ___arguments.context.pageData['invalidCodeValue'] = false;
  passCode = (getObjectProperty(___arguments.context.pageData, 'passcodeValue'));
  carrier = (await Backendless.Data.of('carrier_verification').find(Backendless.DataQueryBuilder.create().setWhereClause((String('passcode = ') + String(passCode))).setPageSize(1)));
  if (carrier.length == 0) {
    ___arguments.context.pageData['invalidCodeValue'] = true;
    ___arguments.context.pageData['carrierDetailsVisibility'] = false;
  } else {
    dotNumber = carrier[0];
    carrierDetails = (await Backendless.Data.of('carrier_pull').find(Backendless.DataQueryBuilder.create().setWhereClause((String('dot_number = ') + String(getObjectProperty(dotNumber, 'dot_number')))).setPageSize(1)));
    returnedCarrierDetails = carrierDetails[0];
    await setCarrierProperties();
    ___arguments.context.pageData['pageTitle'] = (String('Fleet Sustainability Index emissions estimates for ') + String(textToTitleCase(getObjectProperty(returnedCarrierDetails, 'name'))));
    ___arguments.context.pageData['carrierDetailsVisibility'] = true;
    ___arguments.context.pageData['logo'] = (getObjectProperty(dotNumber, 'logo'));
  }

  },
  /* handler:onClick */
  /* content */
}))

